import cn from 'classnames';
import React from 'react';
import { get, useFormContext } from 'react-hook-form';

type InputType = 'text' | 'number';

interface InputProps {
  label: string;
  name: string;
  type?: InputType;
  required?: boolean;
  disabled?: boolean;
}

function Input(props: InputProps) {
  const { label, name, type, required, disabled } = props;
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const textType = type === 'text';
  return (
    <li>
      <label
        className={cn('input', {
          required,
          string: textType,
          stringish: textType,
        })}
        htmlFor={name}
      >
        <p>{label}</p>
      </label>
      <input
        type={type}
        {...register(name)}
        defaultValue={getValues(name)}
        id={name}
        disabled={disabled}
      />
      <p className="inline-errors">{get(errors, name)?.message}</p>
    </li>
  );
}

Input.defaultProps = {
  type: 'text',
  required: false,
};

export default Input;
