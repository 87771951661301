export default function addIdempotentListener(
  element: HTMLElement,
  eventName: string,
  callback: () => void,
  callbackName: string
) {
  const attachedListeners = element.dataset['attachedListeners']
    ? (JSON.parse(element.dataset['attachedListeners']) as string[])
    : [];
  const callbackIdentity = `${callbackName}-${Array.from(
    element.classList
  ).join('-')}-${element.id}`;

  if (!attachedListeners.includes(callbackIdentity)) {
    element.dataset['attachedListeners'] = JSON.stringify([
      ...attachedListeners,
      callbackIdentity,
    ]);
    element.addEventListener(eventName, callback);
  }
}
