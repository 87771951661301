import React, { ReactNode } from 'react';

interface PanelProps {
  children: ReactNode;
  description?: string;
}

function Panel({ description, children }: PanelProps) {
  return (
    <div className="panel">
      {description && <h3>{description}</h3>}
      <div className="panel_contents">{children}</div>
    </div>
  );
}

export default Panel;
