import React, { useId } from 'react';
import { get, useFormContext } from 'react-hook-form';

interface CheckboxProps {
  label: string;
  name: string;
  hint?: string;
  disabled?: boolean;
}

function Checkbox(props: CheckboxProps) {
  const { label, name, hint, disabled } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const id = useId();

  return (
    <li className="input optional">
      <label htmlFor={id} className="string input required stringish">
        <p>{label}</p>
      </label>
      <input type="checkbox" {...register(name)} disabled={disabled} id={id} />
      <p className="inline-hints">{hint}</p>
      <p className="inline-errors">{get(errors, name)?.message}</p>
    </li>
  );
}

export default Checkbox;
