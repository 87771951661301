import React, { MouseEvent } from 'react';

import { HTTPMethod, TypeformQuestion } from '../types';
import {
  Actions,
  Checkbox,
  DatePicker,
  Form,
  Input,
  ReactSelect,
  Select,
  Submit,
  TextArea,
  useAdminForm,
  withQuery,
} from './admin';

interface QuestionsAndAnswers {
  [key: string]: string[] | string | boolean | number;
}

interface SurveyResponse {
  id: string;
  questionsAndAnswers: QuestionsAndAnswers;
}

interface SurveyResponseProps {
  method: HTTPMethod;
  path: string;
  surveyResponse: SurveyResponse;
  typeformFieldsConfig: TypeformQuestion[];
}

function SurveyResponseForm(props: SurveyResponseProps) {
  const {
    method,
    path,
    surveyResponse: initialSurveyResponse,
    typeformFieldsConfig,
  } = props;
  const {
    questionsAndAnswers: initialQuestionsAndAnswers,
  } = initialSurveyResponse;

  const transformQuestionsAndAnswersToSurveyResponse = (
    questionsAndAnswers: QuestionsAndAnswers
  ): SurveyResponse => ({
    ...initialSurveyResponse,
    questionsAndAnswers,
  });

  const adminForm = useAdminForm<QuestionsAndAnswers>({
    path,
    method,
    initialValue: initialQuestionsAndAnswers,
    serializer: transformQuestionsAndAnswersToSurveyResponse,
  });

  const confirmBeforeSave = (event: MouseEvent<HTMLInputElement>): void => {
    window.confirm('Are you sure you want to change user response?') ||
      event.preventDefault();
  };

  return (
    <Form description="" {...adminForm}>
      {typeformFieldsConfig.map((field) => {
        switch (field.type) {
          case 'short_text':
            return (
              <Input
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                required={field.required}
                key={field.title}
              />
            );
          case 'email':
            return (
              <Input
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                required={field.required}
                key={field.title}
              />
            );
          case 'phone_number':
            return (
              <Input
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                required={field.required}
                key={field.title}
              />
            );
          case 'dropdown':
            return (
              <Select
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                required={field.required}
                options={field.choices.map((text) => text.text)}
                key={field.title}
              />
            );
          case 'multiple_choice':
            return (
              <ReactSelect
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                required={field.required}
                options={field.choices.map((text) => text.text)}
                multiple={field.allowMultipleSelection}
                withCustomOption={field.allowOtherChoice}
                key={field.title}
              />
            );
          case 'date':
            return (
              <DatePicker
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                required={field.required}
                key={field.title}
              />
            );
          case 'yes_no':
            return (
              <Checkbox
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                key={field.title}
              />
            );
          case 'long_text':
            return (
              <TextArea
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                options={{ rows: 20 }}
                key={field.title}
              />
            );
          case 'number':
            return (
              <Input
                label={field.title}
                name={field.title.toLocaleLowerCase()}
                type="number"
                required={field.required}
                key={field.title}
              />
            );
          default:
            return 'Unsupported type';
        }
      })}
      <Actions>
        <Submit onClick={confirmBeforeSave} text="Save/Change Response" />
      </Actions>
    </Form>
  );
}

export default withQuery(SurveyResponseForm);
