const englishOrdinalRules = new Intl.PluralRules('en-US', {
  type: 'ordinal',
});

const suffixes = {
  zero: '',
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
  many: '',
};

function ordinalNumber(num: number) {
  const suffixType = englishOrdinalRules.select(num);
  const suffix = suffixes[suffixType];
  return suffix && `${num}${suffix}`;
}

export default ordinalNumber;
