import Compressor from '@uppy/compressor';
import { Core, StatusBar, XHRUpload } from 'uppy';

import addIdempotentListener from '../utils/addIdempotentListener';
import cropbox from '../utils/cropbox';

function FileUpload(uploadButton) {
  const {
    dataset: { uppy: uppyDatatset },
  } = uploadButton;
  const {
    previewWrapperSelector,
    dataSelector,
    maxFileSize,
    aspectRatio,
  } = JSON.parse(uppyDatatset);

  const hiddenInput = document.querySelector(dataSelector);
  const imagePreviewWrapper = document.querySelector(previewWrapperSelector);
  const imagePreview = imagePreviewWrapper.querySelector('img');

  const uppy = new Core({
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: true,
  })
    .use(StatusBar, {
      target: imagePreviewWrapper,
      hideCancelButton: true,
    })
    .use(XHRUpload, {
      endpoint: '/api/v1/upload',
    })
    .use(Compressor, { convertSize: maxFileSize });

  let cropper = null;
  const acceptedFileTypes = ['image/jpeg', 'image/png'];

  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = acceptedFileTypes.join(', ');
  fileInput.addEventListener('change', (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;
    const file = files[0];

    const previousFiles = uppy.getFiles();
    uppy.cancelAll();
    try {
      uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file,
      });
    } catch (err) {
      uppy.info(err, 5000);
      if (previousFiles.length === 1) {
        uppy.addFile(previousFiles[0]);
      }
    }
    if (cropper) cropper.destroy();
    imagePreview.removeAttribute('src');
  });

  addIdempotentListener(
    uploadButton,
    'click',
    (event) => {
      event.preventDefault();
      fileInput.click();
    },
    'FileUpload-click-uploader'
  );

  uppy.on('upload-success', (file, response) => {
    const uploadedFileData = response.body.data;

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData);
    cropper = cropbox(imagePreview, response.uploadURL, aspectRatio, {
      onCrop(detail) {
        const fileData = JSON.parse(hiddenInput.value);
        fileData.metadata.crop = detail;
        fileData.metadata.width = detail.width;
        fileData.metadata.height = detail.height;
        hiddenInput.value = JSON.stringify(fileData);
      },
    });
  });
}

export default FileUpload;
