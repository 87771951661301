import cn from 'classnames';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { get, useFormContext } from 'react-hook-form';

import { flatpickrDatetimeConfig } from '../../constants';

interface InputProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
}

function Input(props: InputProps) {
  const { label, name, required, disabled } = props;
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  return (
    <li>
      <label
        className={cn('datetime_picker input required stringish', {
          required,
        })}
        htmlFor={name}
      >
        <p>{label}</p>
      </label>
      <Flatpickr
        options={flatpickrDatetimeConfig}
        {...register(name)}
        value={getValues(name)}
        onChange={(date) => setValue(name, date[0])}
        id={name}
        disabled={disabled}
      />
      <p className="inline-errors">{get(errors, name)?.message}</p>
    </li>
  );
}

Input.defaultProps = {
  type: 'text',
  required: false,
};

export default Input;
