import { produce } from 'immer';
import { startCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  EmailTemplate,
  HTTPMethod,
  NotificationClassOption,
  SerializedAutomatedNotification,
  SerializedNotificationClass,
  SerializedTrigger,
  TriggerConfigurator,
} from '../types';
import {
  Actions,
  Checkbox,
  Form,
  Input,
  Inputs,
  NestedFields,
  ReactSelect,
  Submit,
  TextArea,
  useAdminForm,
  withQuery,
} from './admin';
import { OptionType } from './admin/types';
import LoadAutomatedNotification from './LoadAutomatedNotification';
import LoadNotificationClass from './LoadNotificationClass';
import NewTriggerButtonWithMessage from './NewTriggerButtonWithMessage';
import TriggerForm from './TriggerForm';

interface AutomatedNotificationFormProps {
  index: number;
  systemNotificationDestinationsOptions: OptionType[];
  templateNamesOptions: OptionType[];
  triggerOptions: OptionType[];
  triggers: SerializedTrigger[];
}

function AutomatedNotificationForm({
  index,
  systemNotificationDestinationsOptions,
  templateNamesOptions,
  triggerOptions,
  triggers,
}: AutomatedNotificationFormProps) {
  const { watch, setValue } = useFormContext<SerializedNotificationClass>();
  const [sendPushNotification, sendEmail, sendSms, triggerId, isSaved] = watch([
    `automatedNotifications.${index}.sendPushNotification`,
    `automatedNotifications.${index}.sendEmail`,
    `automatedNotifications.${index}.sendSms`,
    `automatedNotifications.${index}.triggerId`,
    `automatedNotifications.${index}.saved`,
  ]);
  const selectedTrigger = triggers.find(
    ({ id }) => String(id) === String(triggerId)
  );

  const availableAudiencesGroups = useMemo(() => {
    if (!selectedTrigger) {
      return null;
    }

    return Object.entries(selectedTrigger.audiencesGroups).reduce<{
      [className: string]: OptionType[];
    }>((acc, [className, audiences]) => {
      const options: OptionType[] = audiences.map((audience) => [
        startCase(audience),
        audience,
      ]);

      acc[className] = options;

      return acc;
    }, {});
  }, [selectedTrigger]);

  return (
    <>
      <Input
        label="Internal Name"
        name={`automatedNotifications.${index}.internalName`}
        disabled={isSaved}
      />
      <Checkbox
        label="Send Push Notification"
        name={`automatedNotifications.${index}.sendPushNotification`}
        disabled={isSaved}
      />
      {sendPushNotification && (
        <Inputs description="Push Notification" disabled={isSaved}>
          <Input
            label="Push Notification Title"
            name={`automatedNotifications.${index}.pushNotificationTitle`}
            disabled={isSaved}
          />
          <Input
            label="Push Notification Body"
            name={`automatedNotifications.${index}.pushNotificationBody`}
            disabled={isSaved}
          />
          <ReactSelect
            label="Push Notification Destination"
            name={`automatedNotifications.${index}.pushNotificationDestination`}
            options={systemNotificationDestinationsOptions}
            disabled={isSaved}
          />
          <TextArea
            label="(Optional) Push Notification Full Message"
            name={`automatedNotifications.${index}.pushNotificationFullMessage`}
            hint="This is for messages that you can view as a large message. If you use this, it will take the user to this message and not the destination"
            disabled={isSaved}
          />
          <Checkbox
            label="Hide on notifications list"
            name={`automatedNotifications.${index}.hideOnNotificationsList`}
            disabled={isSaved}
          />
          <Checkbox
            label="Use notification email if push fails"
            name={`automatedNotifications.${index}.sendPushAsEmailOnPushFailure`}
            disabled={isSaved}
          />
        </Inputs>
      )}
      <Checkbox
        label="Send Email"
        name={`automatedNotifications.${index}.sendEmail`}
        disabled={isSaved}
      />
      {sendEmail && (
        <Inputs description="Email" disabled={isSaved}>
          <Checkbox
            label="Send Email on Push Failure"
            name={`automatedNotifications.${index}.sendEmailOnPushFailure`}
            disabled={isSaved}
          />
          <ReactSelect
            label="Template Name"
            name={`automatedNotifications.${index}.templateName`}
            options={templateNamesOptions}
            disabled={isSaved}
          />
        </Inputs>
      )}
      <Checkbox
        label="Send SMS"
        name={`automatedNotifications.${index}.sendSms`}
        disabled={isSaved}
      />
      {sendSms && (
        <Inputs description="SMS Notification" disabled={isSaved}>
          <Checkbox
            label="Send SMS on Push Failure"
            name={`automatedNotifications.${index}.sendSmsOnPushFailure`}
            disabled={isSaved}
          />
          <TextArea
            label="SMS Body"
            name={`automatedNotifications.${index}.smsBody`}
            disabled={isSaved}
          />
        </Inputs>
      )}
      <ReactSelect
        label="Trigger"
        name={`automatedNotifications.${index}.triggerId`}
        options={triggerOptions}
        disabled={isSaved}
      />
      {availableAudiencesGroups && selectedTrigger && (
        <Inputs description="Audiences Groups" disabled={isSaved}>
          {Object.keys(selectedTrigger?.audiencesGroups).map(
            (className) =>
              availableAudiencesGroups[className] && (
                <ReactSelect
                  key={className}
                  label={startCase(className)}
                  name={`automatedNotifications.${index}.audiencesGroups[${className}]`}
                  options={availableAudiencesGroups[className]!}
                  disabled={isSaved}
                  multiple
                />
              )
          )}
        </Inputs>
      )}
      <button
        type="button"
        onClick={() =>
          setValue(`automatedNotifications.${index}.saved`, !isSaved)
        }
      >
        {isSaved ? 'Edit' : 'Save'}
      </button>
    </>
  );
}

function SubmitIfSaved() {
  const { watch } = useFormContext<SerializedNotificationClass>();
  const { automatedNotifications } = watch();
  const allEmailsAreSaved = automatedNotifications.every(({ saved }) => saved);

  return (
    <>
      {!allEmailsAreSaved && (
        <p className="notice-danger">
          Cannot create Notification class while emails are still in edit mode
          and not saved. Please save all emails and try again.
        </p>
      )}
      <Submit disabled={!allEmailsAreSaved} />
    </>
  );
}

const emptyAutomatedNotification: SerializedAutomatedNotification = {
  id: null,
  templateName: '',
  internalName: '',
  smsBody: '',
  pushNotificationTitle: '',
  pushNotificationBody: '',
  pushNotificationFullMessage: '',
  sendPushNotification: false,
  sendSms: false,
  sendEmailOnPushFailure: false,
  sendSmsOnPushFailure: false,
  sendEmail: false,
  pushNotificationDestination: '',
  hideOnNotificationsList: false,
  notificationClassName: null,
  triggerId: '',
  audiencesGroups: {},
  saved: false,
};

interface SystemNotificationDestination {
  value: number;
  label: string;
}

interface NotificationClassFormProps {
  notificationClass: SerializedNotificationClass;
  path: string;
  method: HTTPMethod;
  notificationClasses: NotificationClassOption[];
  systemNotificationDestinations: SystemNotificationDestination[];
  templateNames: EmailTemplate[];
  automatedNotifications: SerializedAutomatedNotification[];

  triggers: SerializedTrigger[];
  triggersPath: string;
  createTriggerPath: string;
  triggerConfigurator: TriggerConfigurator;
}

function addSavedToAutomatedNotifications(
  notificationClass: SerializedNotificationClass
): SerializedNotificationClass {
  return produce(notificationClass, (draft) => {
    draft.automatedNotifications.forEach(
      (automatedNotification) => (automatedNotification.saved = true)
    );
  });
}

function NotificationClassForm(props: NotificationClassFormProps) {
  const {
    notificationClass: initialNotificationClass,
    path,
    method,
    notificationClasses,
    systemNotificationDestinations,
    templateNames,
    automatedNotifications: allAutomatedNotifications,

    triggers: initialTriggers,
    triggersPath,
    triggerConfigurator,
    createTriggerPath,
  } = props;

  const adminForm = useAdminForm<SerializedNotificationClass>({
    initialValue: addSavedToAutomatedNotifications(initialNotificationClass),
    path,
    method,
  });
  const {
    formState: { isValid },
    watch,
    setValue,
  } = adminForm.formMethods;

  const [triggers, setTriggers] = useState(initialTriggers);
  const [showNewTriggerForm, setShowNewTriggerForm] = useState(false);
  const [showTriggerSuccess, setShowTriggerSuccces] = useState(false);
  const automatedNotifications = watch('automatedNotifications');

  const systemNotificationDestinationsOptions: OptionType[] = systemNotificationDestinations.map(
    ({ value, label }) => [label, value]
  );
  const templateNamesOptions: OptionType[] = templateNames.map(
    ({ slug, name }) => [name, slug]
  );
  const triggerOptions: OptionType[] = triggers.map(({ id, internalName }) => [
    internalName,
    String(id),
  ]);

  const onAddTrigger = (newTrigger: SerializedTrigger) => {
    setTriggers([newTrigger, ...triggers]);
    setShowNewTriggerForm(false);
    setShowTriggerSuccces(true);
  };

  const addAutomatedNotifications = (
    newAutomatedNotifications: SerializedAutomatedNotification[]
  ) => {
    const copiedAutomatedNotifications = newAutomatedNotifications.map(
      (newAutomatedNotification) => ({
        ...newAutomatedNotification,
        internalName: `Copy of ${newAutomatedNotification.internalName}`,
        id: null,
      })
    );

    setValue('automatedNotifications', [
      ...automatedNotifications,
      ...copiedAutomatedNotifications,
    ]);
  };

  return (
    <div className="panel">
      <div className="panel_contents">
        <LoadNotificationClass
          notificationClasses={notificationClasses}
          addAutomatedNotifications={addAutomatedNotifications}
        />
        {showNewTriggerForm ? (
          <TriggerForm
            triggerConfigurator={triggerConfigurator}
            createTriggerPath={createTriggerPath}
            onSuccess={onAddTrigger}
            method="POST"
          />
        ) : (
          <NewTriggerButtonWithMessage
            onClick={() => setShowNewTriggerForm(true)}
            showMessage={showTriggerSuccess}
          />
        )}
        <a href={triggersPath} target="_blank" rel="noreferrer">
          View All Triggers
        </a>
        <Form description="Notification Class" {...adminForm}>
          <Input label="Internal Name" name="internalName" required />
          <NestedFields
            description="Automated Notifications"
            resourceName="Automated Notification"
            name="automatedNotifications"
            defaultObject={emptyAutomatedNotification}
            requireRemoveConfirmation
          >
            {(automatedNotificationIndex) => (
              <AutomatedNotificationForm
                index={automatedNotificationIndex}
                systemNotificationDestinationsOptions={
                  systemNotificationDestinationsOptions
                }
                templateNamesOptions={templateNamesOptions}
                triggerOptions={triggerOptions}
                triggers={triggers}
              />
            )}
          </NestedFields>
          <LoadAutomatedNotification
            allAutomatedNotifications={allAutomatedNotifications}
            addAutomatedNotification={(automatedNotification) =>
              addAutomatedNotifications([automatedNotification])
            }
          />
          <Actions>
            <SubmitIfSaved />
            {!isValid && (
              <h1 className="notice-danger">
                ⚠️⚠️⚠️ Validation errors prevented this form from being
                submitted. Please correct errors and try again. ⚠️⚠️⚠️
              </h1>
            )}
          </Actions>
        </Form>
      </div>
    </div>
  );
}

export default withQuery(NotificationClassForm);
