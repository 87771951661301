import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { FieldValues, Path, useForm, UseFormProps } from 'react-hook-form';

import { HTTPMethod } from '../../types';
import { SuccessfullApiResponse, ValidationErrorApiResponse } from './types';
import { csrfToken } from './utils';

type ErrorsMessages = string[];
type ErrorsWithPaths<T> = [Path<T>, ErrorsMessages][];
type ErrorsObject = {
  [key: string]: ErrorsMessages | ErrorsObject | ErrorsObject[];
};
type ErrorsObjectValue = ErrorsMessages | ErrorsObject | ErrorsObject[];

function isErrorsMessages(object: ErrorsObjectValue): object is ErrorsMessages {
  if (!Array.isArray(object)) return false;
  const hasNonStringValues = Object.values(object).find(
    (value) => typeof value !== 'string'
  );
  if (hasNonStringValues) return false;
  return true;
}

function joinErrorKeyPrefixes(prefixes: Array<string | null>) {
  return prefixes.filter((el) => el !== null).join('.');
}

export function normalizeErrors<T>(
  object: ErrorsObjectValue,
  prefix: string | null = null
): ErrorsWithPaths<T> {
  if (isErrorsMessages(object)) {
    return [[prefix as Path<T>, object]];
  }
  return Object.entries(object).reduce<[Path<T>, ErrorsMessages][]>(
    (result, [key, value]) => [
      ...result,
      ...normalizeErrors<T>(value, joinErrorKeyPrefixes([prefix, key])),
    ],
    []
  );
}

interface UseAdminFormProps<FormType extends FieldValues, ResponseType> {
  initialValue?: UseFormProps<FormType>['defaultValues'];
  path: string;
  method: HTTPMethod;
  onSuccess?: (returnedResource: ResponseType) => void;
  serializer?: (formValue: FormType) => unknown;
}

function useAdminForm<FormType extends FieldValues, ResponseType = FormType>(
  props: UseAdminFormProps<FormType, ResponseType>
) {
  const { initialValue, path, method, onSuccess, serializer } = props;
  const submitForm = async (formResource: FormType) => {
    const resource = serializer ? serializer(formResource) : formResource;

    return axios({
      method,
      url: path,
      data: { resource },
      headers: {
        'X-CSRF-Token': csrfToken() || '',
        'Content-Type': 'application/json',
      },
    });
  };

  const formMethods = useForm<FormType>({
    defaultValues: initialValue,
    reValidateMode: 'onSubmit',
  });
  const { reset, setError } = formMethods;

  const mutation = useMutation<
    AxiosResponse<SuccessfullApiResponse<ResponseType>>,
    AxiosError<ValidationErrorApiResponse<FormType>>,
    FormType
  >({
    mutationFn: submitForm,
    onSuccess: ({ data: { resource, collectionUrl } }) => {
      if (onSuccess === undefined) {
        window.location.href = collectionUrl;
      } else {
        onSuccess(resource);
        reset();
      }
    },
    onError: ({ response }) => {
      if (response?.status === 422) {
        normalizeErrors<FormType>(response.data.errors).forEach(
          ([key, errors]) => {
            errors.forEach((error) => {
              setError(key as Path<FormType>, {
                type: 'manual',
                message: error,
              });
            });
          }
        );
      }
    },
  });

  return { formMethods, mutation };
}

export default useAdminForm;
