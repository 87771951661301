import React from 'react';

function VariablesForSurveys() {
  return (
    <>
      <li>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="input">Variables for Surveys/Emails/Reminders:</label>
      </li>
      <li></li>
      <li>
        *|SURVEY_DEADLINE|* - The date and time the survey closes and can no
        longer ber responded to. Example: Thursday, 03/05/2020 9:00am EST /
        6:00am PST
      </li>
      <li>
        *|SURVEY_LINK|* - The link to the survey. Example:
        https://betteroffinside.com/survey/1
      </li>
      <li>
        *|EVENT_TITLE|* - The event title (e.g. The Adventures Eaters Brunch)
      </li>
      <li>*|MEETING_DATE|* - The meeting date (e.g. Thursday, 03/05/2020)</li>
      <li>
        *|MEETING_TIME|* - The meeting time (e.g. 9:00am EST / 6:00am PST)
      </li>
      <li>
        *|MEMBER_FULL_NAME|* - The survey receiver full name (e.g. Joe Doe)
      </li>
      <li>
        *|MEMBER_EMAIL|* - The survey receiver email (e.g joe.doe@example.com)
      </li>
      <li>*|FNAME|* - The survey receiver first name (e.g Joe)</li>
    </>
  );
}

export default VariablesForSurveys;
