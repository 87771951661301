import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import get from './get';

const VariablesClass = z.object({
  description: z.string(),
  variablesWithDescriptions: z.array(z.tuple([z.string(), z.string()])),
});

const responseSchema = z.array(VariablesClass);

function useVariablesClasses(triggerId: number | null) {
  const queryString = triggerId ? `trigger_id=${triggerId}` : '';

  return useSuspenseQuery({
    queryKey: ['variablesClasses', triggerId],
    queryFn: () =>
      get(`/admin/variables/variables_classes?${queryString}`, responseSchema),
  }).data;
}

export default useVariablesClasses;
