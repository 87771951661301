import React, { useRef, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

interface CopyValueProps {
  copyValue: string;
}

function CopyButton({ copyValue }: CopyValueProps) {
  const [, copy] = useCopyToClipboard();
  const [copiedState, setCopiedState] = useState(false);
  const timer: { current: number | null } = useRef(null);
  const onCopy = () => {
    setCopiedState(true);
    copy(copyValue);
    if (!timer || !timer.current) {
      timer.current = setTimeout(() => {
        setCopiedState(false);
        timer.current = null;
      }, 2000);
    }
  };

  return (
    <button
      className={
        copiedState ? 'btn -outline-success w-100' : 'btn -primary w-100'
      }
      onClick={onCopy}
    >
      {copiedState ? 'Copied!' : 'Copy'}
    </button>
  );
}

export default CopyButton;
