import { UseMutationResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { ReactNode } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

import { SubmitContext } from '../../contexts';
import Inputs from './Inputs';
import { SuccessfullApiResponse, ValidationErrorApiResponse } from './types';

interface FormProps<FormType extends FieldValues, ResponseType> {
  description: string;
  formMethods: UseFormReturn<FormType>;
  mutation: UseMutationResult<
    AxiosResponse<SuccessfullApiResponse<ResponseType>>,
    AxiosError<ValidationErrorApiResponse<FormType>>,
    FormType
  >;
  children: ReactNode;
}

function Form<FormType extends FieldValues, ResponseType>(
  props: FormProps<FormType, ResponseType>
) {
  const { description, formMethods, mutation, children } = props;
  const { handleSubmit, clearErrors } = formMethods;

  const onSubmit = mutation.mutate as SubmitHandler<FormType>;
  const { isPending } = mutation;

  return (
    <FormProvider {...formMethods}>
      <SubmitContext.Provider value={{ isPending }}>
        <form
          onSubmit={(e) => {
            clearErrors();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Inputs description={description} atRoot>
            {children}
          </Inputs>
        </form>
      </SubmitContext.Provider>
    </FormProvider>
  );
}

export default Form;
