import range from 'lodash/range';
import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useList } from 'react-use';

import { flatpickrDatetimeConfig } from '../constants';

interface DateTimeInputProps {
  initialValue?: string;
  name: string;
}

function DateTimeInput({ initialValue, name }: DateTimeInputProps) {
  const [value, setValue] = useState(initialValue && new Date(initialValue));

  let stringValue: string;
  if (value) {
    const local = new Date(value);
    local.setMinutes(value.getMinutes() - value.getTimezoneOffset());
    stringValue = local.toJSON().slice(0, -1);
  } else {
    stringValue = '';
  }

  return (
    <Flatpickr
      options={flatpickrDatetimeConfig}
      value={value && stringValue}
      defaultValue={stringValue}
      name={name}
      className="flatpicker"
      onChange={(newDate) => newDate[0] && setValue(newDate[0])}
    />
  );
}

interface AdminMultipleFieldProps {
  values: string[];
  fieldName: string;
  addDescription: string;
}

function AdminMultipleField({
  values: defaultValues,
  fieldName,
  addDescription,
}: AdminMultipleFieldProps) {
  const [keys, { push, removeAt }] = useList(range(defaultValues.length || 1));
  const nextKey = (keys[keys.length - 1] as number) + 1;

  return (
    <>
      {keys.map((key, index) => (
        <div key={key}>
          <DateTimeInput
            initialValue={defaultValues[index]}
            name={`${fieldName}[${key}]`}
          />
          <button type="button" onClick={() => removeAt(index)}>
            Remove
          </button>
        </div>
      ))}
      <br />
      <button type="button" onClick={() => push(nextKey)}>
        {addDescription}
      </button>
    </>
  );
}

export default AdminMultipleField;
