import Cropper from 'cropperjs';

export default function cropbox(img, url, aspectRatio, { onCrop }) {
  const image = img;
  image.src = url;

  return new Cropper(image, {
    aspectRatio,
    viewMode: 3,
    guides: false,
    autoCropArea: 1.0,
    background: false,
    zoomable: false,
    checkCrossOrigin: false,
    crop: (event) => onCrop(event.detail),
  });
}
