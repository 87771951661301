import cn from 'classnames';
import React from 'react';
import { get, useFormContext } from 'react-hook-form';

import { OptionType } from './types';

function Option(props: { option: OptionType }) {
  const { option } = props;
  if (Array.isArray(option)) {
    return <option value={option[1]}>{option[0]}</option>;
  }
  return <option value={option}>{option}</option>;
}

interface SelectProps {
  label?: string;
  description?: {
    summary: string;
    text: string;
  };
  name: string;
  options: OptionType[];
  required?: boolean;
  includeBlank?: boolean | string;
  disabled?: boolean;
}

function Select(props: SelectProps) {
  const {
    label,
    description,
    name,
    options,
    required,
    includeBlank,
    disabled,
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const blankOption = includeBlank === true ? '' : includeBlank;

  return (
    <li className={cn('select', 'input', { required })}>
      {label && (
        <label className={cn('select', 'input', { required })} htmlFor={name}>
          <p>{label}</p>
        </label>
      )}
      <select {...register(name)} id={name} disabled={disabled}>
        {includeBlank && <Option option={[blankOption as string, '']} />}
        {options.map((option, i) => (
          <Option option={option} key={`${name}-${i}`} />
        ))}
      </select>
      <p className="inline-errors">{get(errors, name)?.message}</p>
      {description && (
        <details>
          <summary>{description.summary}</summary>
          {description.text.split('\n').map((text) => (
            <p>{text}</p>
          ))}
        </details>
      )}
    </li>
  );
}

export default Select;
