import groupBy from 'lodash/groupBy';
import React, { MouseEvent, useId, useState } from 'react';
import Select from 'react-select';

import { SerializedAutomatedNotification } from '../types';
import { Inputs } from './admin';

interface LoadAutomatedNotificationProps {
  allAutomatedNotifications: SerializedAutomatedNotification[];
  addAutomatedNotification: (
    newAutomatedNotification: SerializedAutomatedNotification
  ) => void;
}

function LoadAutomatedNotification({
  allAutomatedNotifications,
  addAutomatedNotification,
}: LoadAutomatedNotificationProps) {
  const automatedNotificationsOptions = (
    automatedNotificationsCollection: SerializedAutomatedNotification[]
  ) =>
    automatedNotificationsCollection.map(({ id, internalName: label }) => ({
      value: id || '',
      label,
    }));

  const groupedAutomatedNotifications = Object.entries(
    groupBy(allAutomatedNotifications, 'notificationClassName')
  ).map(([notificationClass, automatedNotifications]) => ({
    label: notificationClass,
    options: automatedNotificationsOptions(automatedNotifications),
  }));

  const [
    selectedAutomatedNotification,
    setSelectedAutomatedNotification,
  ] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const automatedNotification =
    selectedAutomatedNotification &&
    allAutomatedNotifications.find(
      (searchedNotificationClass) =>
        searchedNotificationClass.id === selectedAutomatedNotification.value
    );

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!automatedNotification) return;
    addAutomatedNotification(automatedNotification);
    setSelectedAutomatedNotification(null);
  };

  const id = useId();

  return (
    <Inputs description="Copy single Automated Notification" atRoot>
      <li>
        <label htmlFor={id} className="string input required stringish">
          <p>Select existing Automated Notification</p>
        </label>
        <Select
          id={id}
          value={selectedAutomatedNotification}
          onChange={setSelectedAutomatedNotification}
          options={groupedAutomatedNotifications}
          menuPosition="fixed"
        />
      </li>
      <button
        className="btn -primary -lg"
        disabled={!selectedAutomatedNotification}
        onClick={onClick}
      >
        Copy Automated Notification
      </button>
    </Inputs>
  );
}

export default LoadAutomatedNotification;
