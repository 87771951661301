import React, { ReactNode } from 'react';

interface InputsProps {
  description?: string;
  children: ReactNode;
  disabled?: boolean;
  atRoot?: boolean;
}

function Inputs({
  description,
  children,
  disabled,
  atRoot = false,
}: InputsProps) {
  const fieldset = (
    <fieldset className="inputs" disabled={disabled}>
      {description && (
        <legend>
          <span>{description}</span>
        </legend>
      )}
      <ol>{children}</ol>
    </fieldset>
  );

  if (atRoot) {
    return fieldset;
  } else {
    return <li>{fieldset}</li>;
  }
}

export default Inputs;
