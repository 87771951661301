import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox, Input, ReactSelect, Select } from './admin';
import TimingForm from './TimingForm';

interface SurveyReminderFormProps {
  allReminderTypes: [string, string][];
  templateNamesOptions: [string, string][];
  namespace: string;
}

function SurveyReminderForm({
  allReminderTypes,
  namespace,
  templateNamesOptions,
}: SurveyReminderFormProps) {
  const { watch } = useFormContext();
  const sendSms = watch(`${namespace}.sendSms` as const);
  const sendPushNotification = watch(
    `${namespace}.sendPushNotification` as const
  );
  const sendEmail = watch(`${namespace}.sendEmail` as const);
  return (
    <>
      <Select
        options={allReminderTypes}
        label="Type"
        name={`${namespace}.reminderType`}
      />
      <Checkbox label="SendSms" name={`${namespace}.sendSms`} />
      {sendSms && <Input label="Sms Body" name={`${namespace}.smsBody`} />}
      <Checkbox
        label="Send Push Notification"
        name={`${namespace}.sendPushNotification`}
      />
      {sendPushNotification && (
        <>
          <Input
            label="Push Notification Body"
            name={`${namespace}.pushNotificationBody`}
          />
          <Input
            label="Push Notification Title"
            name={`${namespace}.pushNotificationTitle`}
          />
        </>
      )}
      <Checkbox label="Send Email" name={`${namespace}.sendEmail`} />
      {sendEmail && (
        <ReactSelect
          label="Email Template"
          name={`${namespace}.emailSlug`}
          options={templateNamesOptions}
        />
      )}
      <TimingForm namespace={`${namespace}.timing`} timingTypeOptions={null} />
    </>
  );
}

export default SurveyReminderForm;
