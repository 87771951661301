import React, { FC } from 'react';

import { Suspense } from '.';

function withSuspense<T extends object>(Component: FC<T>) {
  return (props: T) => (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
}

export default withSuspense;
