import React, { ReactNode } from 'react';

interface ColumnProps {
  children: ReactNode;
  numberOfColumns?: number;
}

function Column({ children, numberOfColumns = 1 }: ColumnProps) {
  const width = 100.0 / numberOfColumns;
  return (
    <div className="column" style={{ width: `${width}%` }}>
      {children}
    </div>
  );
}

export default Column;
