import range from 'lodash/range';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox, Inputs, Select } from './admin';

interface TimingFormProps {
  timingTypeOptions: [string, string][] | null;
  namespace: string;
}

function TimingFrom({ timingTypeOptions, namespace }: TimingFormProps) {
  const { watch } = useFormContext();
  const [atTime] = watch([`${namespace}.atTime`]);
  return (
    <Inputs description="Timing">
      {timingTypeOptions && (
        <Select name={`${namespace}.type`} options={timingTypeOptions} />
      )}
      <Checkbox
        label="Run at a specific time"
        name={`${namespace}.atTime`}
        hint={
          '"Run 2 days, 10 hours and 20 minutes before" vs "Run 2 days before at 10:20 AM"'
        }
      />
      <Select label="Weeks" name={`${namespace}.weeks`} options={range(53)} />
      <Select label="Days" name={`${namespace}.days`} options={range(7)} />
      {!atTime && (
        <Inputs>
          <Select
            label="Hours"
            name={`${namespace}.hours`}
            options={range(24)}
          />
          <Select
            label="Minutes"
            name={`${namespace}.minutes`}
            options={range(60)}
          />
        </Inputs>
      )}
      {atTime && (
        <Inputs description="Run at:">
          <Select
            label="Hour"
            name={`${namespace}.atHour`}
            options={range(13)}
          />
          <Select
            label="AM/PM"
            name={`${namespace}.atAmPm`}
            options={['am', 'pm']}
          />
          <Select
            label="Minute"
            name={`${namespace}.atMinute`}
            options={range(60)}
          />
        </Inputs>
      )}
    </Inputs>
  );
}

export default TimingFrom;
