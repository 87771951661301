function ensure<T>(
  argument: T | undefined | null,
  message = 'This value should never be empty'
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export default ensure;
