import React, { MouseEvent } from 'react';

interface NewTriggerButtonWithMessageProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  showMessage: boolean;
}

function NewTriggerButtonWithMessage({
  onClick,
  showMessage,
}: NewTriggerButtonWithMessageProps) {
  return (
    <div>
      <button
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
      >
        New trigger
      </button>
      {showMessage && (
        <div className="flashes">
          <div className="flash flash_notice">
            Trigger was successfully created.
          </div>
        </div>
      )}
    </div>
  );
}

export default NewTriggerButtonWithMessage;
