import { z } from 'zod';

async function get<T extends z.ZodTypeAny>(path: string, schema: T) {
  const response = await fetch(path, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const json = await response.json();
  const parsed = schema.safeParse(json);

  if (parsed.success) {
    return parsed.data as z.infer<T>;
  } else {
    const errorMessage = `Error parsing response: ${parsed.error}`;
    console.warn(errorMessage);
    throw new Error(errorMessage);
  }
}

export default get;
