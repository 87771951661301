import Uppy from '@uppy/core';
import { FileInput, useUppy } from '@uppy/react';
import range from 'lodash/range';
import React, { useState } from 'react';

import { emptyQuestion, emptyReminder } from '../constants';
import {
  EmailTemplate,
  HTTPMethod,
  QuestionType,
  SerializedSurvey,
  SerializedTypeformConfig,
  TypeformTheme,
  TypeformWorkspace,
} from '../types';
import ordinalNumber from '../utils/ordinalNumber';
import {
  Actions,
  Checkbox,
  Form,
  Input,
  Inputs,
  NestedFields,
  ReactSelect,
  Select,
  Submit,
  TextArea,
  useAdminForm,
  withQuery,
} from './admin';
import DatePicker from './admin/DatePicker';
import SurveyReminderForm from './SurveyReminderForm';
import VariablesForSurveys from './VariablesForSurveys';

interface SerializedTypeformConfigWithImage extends SerializedTypeformConfig {
  welcomeScreenImageData: string | null;
}

interface SerializedSurveyWithImage extends SerializedSurvey {
  typeformConfig: SerializedTypeformConfigWithImage;
}
interface SurveyProps {
  method: HTTPMethod;
  path: string;
  survey: SerializedSurvey;
  allTypes: [string, string][];
  numberOfMeetings: number;
  allTypeformQuestionTypes: [string, QuestionType][];
  allReminderTypes: [string, string][];
  typeformWorkspaces: TypeformWorkspace[];
  typeformThemes: TypeformTheme[];
  templateNames: EmailTemplate[];
  actionItemNotificationExamplePath: string;
  welcomeScreenImagePath: string;
}

function SurveyForm(props: SurveyProps) {
  const {
    method,
    path,
    survey: initialSurveyClass,
    allTypes,
    allTypeformQuestionTypes,
    allReminderTypes,
    templateNames,
    typeformWorkspaces,
    typeformThemes,
    actionItemNotificationExamplePath,
    numberOfMeetings,
    welcomeScreenImagePath,
  } = props;

  const [welcomeScreenImage, setWelcomeScreenImage] = useState<
    null | string | ArrayBuffer
  >(null);
  const adminForm = useAdminForm<SerializedSurvey, SerializedSurveyWithImage>({
    path,
    method,
    initialValue: initialSurveyClass,
    serializer: (survey) => ({
      ...survey,
      typeformConfig: {
        ...survey.typeformConfig,
        welcomeScreenImageData: welcomeScreenImage,
      },
    }),
  });
  const {
    formMethods: { watch, setValue },
  } = adminForm;
  const [
    surveyType,
    dashboardNotificationEnabled,
    dashboardBoxNotificationEnabled,
    emailNotificationEnabled,
  ] = watch([
    'surveyType',
    'dashboardNotificationEnabled',
    'dashboardBoxNotificationEnabled',
    'emailNotificationEnabled',
  ]);

  const { finalized, publishedAt } = initialSurveyClass;
  const isPublished = publishedAt != null;
  const questionTypes: [
    string,
    string
  ][] = allTypeformQuestionTypes.map(([internalName, type]) => [
    internalName,
    type ? type.toString() : '',
  ]);

  const workspaceHrefsOptions: [
    string,
    string
  ][] = typeformWorkspaces.map(({ href, name }) => [name, href]);

  const themesOptions: [
    string,
    string
  ][] = typeformThemes.map(({ id, name }) => [name, id]);

  const templateNamesOptions: [
    string,
    string
  ][] = templateNames.map(({ slug, name }) => [name, slug]);

  const checkFinalizedAndSubmit = () => {
    setValue('finalized', true);
  };

  const allMeetingNumbers: [string, number][] = range(
    1,
    numberOfMeetings + 1
  ).map((num: number) => [`${ordinalNumber(num)} Meeting`, num]);

  const uppy = useUppy(() => new Uppy());
  uppy.on('file-added', function (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file.data);
    reader.onloadend = function () {
      const base64data = reader.result;
      setWelcomeScreenImage(base64data);
    };
  });

  return (
    <>
      <Form description="Survey" {...adminForm}>
        <Input name="internalName" label="Internal Name" required />
        <Inputs description="Survey Settings" disabled={isPublished}>
          <Select
            label="Type"
            name="surveyType"
            options={allTypes}
            includeBlank
            required
          />
          <Select
            label="Survey Linked To"
            name="meetingNumber"
            options={allMeetingNumbers}
          />
        </Inputs>
        <Inputs description="Survey Content">
          <VariablesForSurveys />
        </Inputs>
        <Inputs disabled={isPublished}>
          {surveyType === 'typeform' && (
            <Input name="typeformUrl" label="Typeform Url" required />
          )}
          {surveyType === 'typeform_hybrid' && (
            <Inputs description="Typeform Config">
              <Input label="Typeform Title" name="typeformConfig.title" />
              <Input
                label="Thank You Screen Title"
                name="typeformConfig.thankyouScreenTitle"
              />
              <TextArea
                label="Welcome Screen Description"
                name="typeformConfig.welcomeScreenDescription"
                options={{ rows: 20 }}
              />
              <Input
                label="Welcome Screen Title"
                name="typeformConfig.welcomeScreenTitle"
              />

              {typeof welcomeScreenImage === 'string' && (
                <img src={welcomeScreenImage} />
              )}
              {typeof welcomeScreenImage !== 'string' && (
                <img src={welcomeScreenImagePath} />
              )}
              <FileInput
                uppy={uppy}
                pretty={true}
                inputName="typeformConfig.welcomeScreenImageData"
              />

              <Select
                label="Workspace"
                name="typeformConfig.workspaceHref"
                options={workspaceHrefsOptions}
              />
              <Select
                label="Theme"
                name="typeformConfig.themeId"
                options={themesOptions}
              />
              <NestedFields
                description="Questions"
                resourceName="Question"
                name="typeformConfig.fields"
                defaultObject={emptyQuestion}
                requireRemoveConfirmation
              >
                {(questionIndex) => {
                  const namespace = `typeformConfig.fields.${questionIndex}`;
                  const questionType = watch(
                    `typeformConfig.fields.${questionIndex}.type` as const
                  );
                  return (
                    <>
                      <li>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="input">Priority</label>
                        {questionIndex + 1}
                      </li>
                      <Input label="Title" name={`${namespace}.title`} />
                      <Input
                        label="Description"
                        name={`${namespace}.description`}
                      />
                      <Checkbox
                        label="Required"
                        name={`${namespace}.required`}
                      />
                      <ReactSelect
                        label="Type"
                        name={`${namespace}.type`}
                        options={questionTypes}
                        disabled={isPublished}
                      />
                      {questionType === 'multiple_choice' && (
                        <>
                          <Checkbox
                            label="Allow Multiple Selection"
                            name={`${namespace}.allowMultipleSelection`}
                          />
                          <Checkbox
                            label="Allow Other Choices"
                            name={`${namespace}.allowOtherChoice`}
                          />
                        </>
                      )}
                      {(questionType === 'dropdown' ||
                        questionType === 'multiple_choice') && (
                        <NestedFields
                          name={`${namespace}.choices`}
                          resourceName="Choice"
                          defaultObject={{ text: '' }}
                        >
                          {(choiceIndex) => (
                            <Input
                              label="Answer Option"
                              name={`${namespace}.choices.${choiceIndex}.text`}
                            />
                          )}
                        </NestedFields>
                      )}
                    </>
                  );
                }}
              </NestedFields>
              {!finalized && (
                <Submit
                  text="Save/Finalize"
                  onClick={checkFinalizedAndSubmit}
                />
              )}
              <li>
                Note: Once survey has been finalized, it can be announced. Note,
                it may not be possible to change the survey once it has been
                finalized. Clicking "Save Survey" at the bottom will not
                Finalize the survey, you need to click "Finalize / Create
                Survey" to do so. This will ALSO save the survey.
              </li>
            </Inputs>
          )}
        </Inputs>
        <Inputs description="Survey Creation/Announcement Trigger">
          <li className="inline-hints">
            This is when the survey can be entered and notifications may be sent
            via email, push notification, text or site dashboard notification,
            topline or big box.
          </li>
          <DatePicker name="announcementAt" label="Trigger" />
        </Inputs>
        <Inputs description="Deadline triggers">
          <li>
            This is when the survey can no longer be entered and users no longer
            see the option to enter it on their dashboard.
          </li>
          <DatePicker name="deadlineAt" label="Deadline Trigger" />
        </Inputs>
        <Inputs description="Survey Content">
          <VariablesForSurveys />
        </Inputs>
        <Checkbox
          label="Dashboard Topline Alerts Reminder"
          name="dashboardNotificationEnabled"
        />
        {dashboardNotificationEnabled && (
          <>
            <img src={actionItemNotificationExamplePath} />
            <Input
              name="dashboardNotificationMessage"
              label="Notification Message"
            />
            <Input name="dashboardNotificationLinkText" label="Link Text" />
          </>
        )}

        <Checkbox
          label="Dashboard Large Box Alert"
          name="dashboardBoxNotificationEnabled"
        />
        {dashboardBoxNotificationEnabled && (
          <>
            <Input name="dashboardBoxNotificationTitle" label="Title" />
            <Input name="dashboardBoxNotificationBody" label="Body" />
            <Input
              name="dashboardBoxNotificationButtonText"
              label="Button Text"
            />
          </>
        )}

        <Checkbox label="Email" name="emailNotificationEnabled" />
        {emailNotificationEnabled && (
          <ReactSelect
            label="Automated Notification Template"
            name="emailNotificationTemplate"
            options={templateNamesOptions}
          />
        )}

        <NestedFields
          description="Reminders"
          resourceName="Reminder"
          name="reminders"
          defaultObject={emptyReminder}
          requireRemoveConfirmation
        >
          {(reminderIndex) => {
            const namespace = `reminders.${reminderIndex}`;
            return (
              <SurveyReminderForm
                namespace={namespace}
                templateNamesOptions={templateNamesOptions}
                allReminderTypes={allReminderTypes}
              />
            );
          }}
        </NestedFields>

        <Inputs description="Response Results Email:">
          <li> Not implemented yet </li>
        </Inputs>

        <Actions>
          <Submit text="Save Survey" />
          {!finalized && (
            <Submit text="Save/Finalize" onClick={checkFinalizedAndSubmit} />
          )}
        </Actions>
      </Form>
    </>
  );
}

export default withQuery(SurveyForm);
