import React, { useMemo } from 'react';

import {
  HTTPMethod,
  SerializedTrigger,
  Timing,
  TriggerConfigurator,
} from '../types';
import {
  Actions,
  Form,
  Input,
  Inputs,
  Select,
  Submit,
  useAdminForm,
  withQuery,
} from './admin';
import { OptionType } from './admin/types';
import TimingForm from './TimingForm';

interface SerializedConditionsConfig {
  className: string;
  conditions: string[];
}

interface SerializedTriggerForm {
  internalName: string;
  type: string;
  conditionsGroups: SerializedConditionsConfig[];
  timing: Timing;
}

interface ConditionsConfig {
  [className: string]: string;
}

interface Trigger extends Omit<SerializedTriggerForm, 'conditionsGroups'> {
  conditionsGroups: ConditionsConfig;
}

function camelCaseToHuman(input: string) {
  return input.replace(/([A-Z])/g, ' $1');
}

const timingTypeOptions: [string, string][] = [
  ['Before', 'before'],
  ['After', 'after'],
];

function transformSerializedTriggerFormToTrigger(
  serializedTriggerForm: SerializedTriggerForm
): Trigger {
  const conditionsGroups = serializedTriggerForm.conditionsGroups.reduce(
    (object, conditionsGroup) => ({
      ...object,
      [conditionsGroup.className]: conditionsGroup.conditions[0],
    }),
    {}
  );
  return { ...serializedTriggerForm, conditionsGroups };
}

function transformTriggerToSerializedTriggerForm(
  trigger: Trigger
): SerializedTriggerForm {
  const conditionsGroups =
    trigger.conditionsGroups &&
    Object.entries(trigger.conditionsGroups).map(([className, condition]) => ({
      className,
      conditions: [condition],
    }));

  return { ...trigger, conditionsGroups };
}

interface TriggerFormProps {
  trigger?: SerializedTriggerForm;
  triggerConfigurator: TriggerConfigurator;
  createTriggerPath: string;
  onSuccess?: (t: SerializedTrigger) => void;
  method: HTTPMethod;
}

function TriggerForm(props: TriggerFormProps) {
  const {
    trigger: initialTriggerForm,
    triggerConfigurator,
    createTriggerPath,
    onSuccess,
    method,
  } = props;

  const initialValue = useMemo(
    () =>
      initialTriggerForm &&
      transformSerializedTriggerFormToTrigger(initialTriggerForm),
    [initialTriggerForm]
  );

  const adminForm = useAdminForm<Trigger, SerializedTrigger>({
    initialValue,
    path: createTriggerPath,
    method,
    onSuccess,
    serializer: transformTriggerToSerializedTriggerForm,
  });
  const {
    formMethods: { watch },
  } = adminForm;
  const [type] = watch(['type']);

  const {
    allTriggersTypes,
    conditionsGroups,
    typesWithTimingSupport,
  } = triggerConfigurator;

  const allTriggersOptions = allTriggersTypes.map<OptionType>(
    ({ type, label }) => [label, type]
  );
  const availableGroups = conditionsGroups.filter((conditionGroup) =>
    conditionGroup.triggers.includes(type)
  );
  const currentTriggerType = allTriggersTypes.find(
    (triggerType) => triggerType.type === type
  );
  const showTiming = typesWithTimingSupport.includes(type);

  return (
    <Form description="Trigger" {...adminForm}>
      <Input label="Internal Name" name="internalName" required />
      <Select
        label="Type"
        description={
          currentTriggerType && {
            summary: 'Description',
            text: currentTriggerType.hint,
          }
        }
        name="type"
        options={allTriggersOptions}
        includeBlank
        required
      />
      <Inputs description="Conditions Groups">
        {availableGroups.map(({ className, conditions, hint }) => (
          <>
            <Select
              label={camelCaseToHuman(className)}
              description={{ summary: 'Description', text: hint }}
              name={`conditionsGroups.${className}` as const}
              options={conditions}
              key={className}
              includeBlank="All"
              required
            />
          </>
        ))}
      </Inputs>
      {showTiming && (
        <TimingForm timingTypeOptions={timingTypeOptions} namespace="timing" />
      )}
      <Actions>
        <Submit />
      </Actions>
    </Form>
  );
}

export default withQuery(TriggerForm);
