import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import get from './get';

const AudiencesGroups = z.record(z.string(), z.array(z.string()));

const Trigger = z.object({
  id: z.number(),
  internalName: z.string(),
  audiencesGroups: AudiencesGroups,
});

const responseSchema = z.object({
  collection: z.array(Trigger),
});

function useTriggers() {
  return useSuspenseQuery({
    queryKey: ['triggers'],
    queryFn: () => get('/admin/triggers', responseSchema),
  }).data;
}

export default useTriggers;
