import React, { MouseEventHandler, useContext } from 'react';

import { SubmitContext } from '../../contexts';

interface InputProps {
  text?: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

function Submit(props: InputProps) {
  const { text, onClick, disabled } = props;
  const { isPending } = useContext(SubmitContext);
  const value = isPending ? 'Loading...' : text;

  if (onClick) {
    return (
      <li>
        <input
          type="submit"
          value={value}
          onClick={onClick}
          disabled={isPending}
        />
      </li>
    );
  }
  return (
    <li>
      <input type="submit" value={value} disabled={disabled || isPending} />
    </li>
  );
}

Submit.defaultProps = {
  text: 'Submit',
};

export default Submit;
