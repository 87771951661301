import cn from 'classnames';
import React from 'react';
import { get, useFormContext } from 'react-hook-form';

interface OptionsObj {
  rows?: number;
}

interface TextAreaProps {
  label: string;
  name: string;
  hint?: string;
  required?: boolean;
  options?: OptionsObj;
  disabled?: boolean;
  rows?: number;
}

function TextArea(props: TextAreaProps) {
  const { label, name, hint, required, options, disabled, rows = 5 } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <li>
      <label
        className={cn('input', {
          required,
          string: true,
          stringish: true,
        })}
        htmlFor={name}
      >
        <p>{label}</p>
      </label>
      <textarea
        id={name}
        {...register(name)}
        {...options}
        rows={rows}
        required={required}
        disabled={disabled}
      />
      {hint && <p className="inline-hints">{hint}</p>}
      <p className="inline-errors">{get(errors, name)?.message}</p>
    </li>
  );
}

TextArea.defaultProps = {
  type: 'text',
  required: false,
};

export default TextArea;
