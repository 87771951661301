import Uppy from '@uppy/core';
import { FileInput, useUppy } from '@uppy/react';
import React, { MouseEvent, useState } from 'react';
import useList from 'react-use/lib/useList';

import { emptyQuestion, emptyReminder } from '../constants';
import {
  EmailTemplate,
  HTTPMethod,
  QuestionType,
  ReminderType,
  SerializedSurveyClass,
  SerializedTrigger,
  SerializedTypeformConfig,
  TriggerConfigurator,
  TypeformTheme,
  TypeformWorkspace,
} from '../types';
import {
  Actions,
  Checkbox,
  Form,
  Input,
  Inputs,
  LinkTo,
  NestedFields,
  ReactSelect,
  Select,
  Submit,
  TextArea,
  useAdminForm,
  withQuery,
} from './admin';
import LoadSurveyClass from './LoadSurveyClass';
import NewTriggerButtonWithMessage from './NewTriggerButtonWithMessage';
import SurveyReminderForm from './SurveyReminderForm';
import TriggerForm from './TriggerForm';
import VariablesForSurveys from './VariablesForSurveys';

interface SerializedTypeformConfigWithImage extends SerializedTypeformConfig {
  welcomeScreenImageData: string | null;
}

interface SerializedSurveyClassWithImage extends SerializedSurveyClass {
  typeformConfig: SerializedTypeformConfigWithImage;
}
interface SurveyClassProps {
  method: HTTPMethod;
  path: string;
  triggers: SerializedTrigger[];
  triggerPath: string;
  triggerConfigurator: TriggerConfigurator;
  triggersPath: string;
  surveyClass: SerializedSurveyClass | false;
  surveyClasses: SerializedSurveyClass[];
  allTypes: [string, string][];
  allTypeformQuestionTypes: [string, QuestionType][];
  allReminderTypes: [string, ReminderType][];
  templateNames: EmailTemplate[];
  typeformWorkspaces: TypeformWorkspace[];
  typeformThemes: TypeformTheme[];
  actionItemNotificationExamplePath: string;
  welcomeScreenImagePath: string;
}

function urlToData(
  url: string,
  callback: (result: string | ArrayBuffer | null) => void
) {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

function SurveyClassForm(props: SurveyClassProps) {
  const {
    method,
    path,
    triggers: initialTriggers,
    triggerPath,
    triggerConfigurator,
    triggersPath,
    surveyClass: initialSurveyClass,
    allTypes,
    allTypeformQuestionTypes,
    allReminderTypes,
    surveyClasses,
    templateNames,
    typeformWorkspaces,
    typeformThemes,
    actionItemNotificationExamplePath,
  } = props;
  const [triggers, { insertAt: insertTrigger }] = useList(initialTriggers);
  const [showTriggerSuccess, setShowTriggerSuccces] = useState(false);
  const [showNewTriggerForm, setShowNewTriggerForm] = useState(false);
  const [welcomeScreenImage, setWelcomeScreenImage] = useState<
    null | string | ArrayBuffer
  >(null);
  const adminForm = useAdminForm<
    SerializedSurveyClass,
    SerializedSurveyClassWithImage
  >({
    path,
    method,
    initialValue: initialSurveyClass || undefined,
    serializer: (surveyClass) => ({
      ...surveyClass,
      typeformConfig: {
        ...surveyClass.typeformConfig,
        welcomeScreenImageData: welcomeScreenImage,
      },
    }),
  });

  const {
    formMethods: { watch, reset },
  } = adminForm;
  const [
    surveyType,
    triggerId,
    emailNotificationEnabled,
    dashboardNotificationEnabled,
    dashboardBoxNotificationEnabled,
    phoneNotificationEnabled,
    nativeAppNotificationEnabled,
    welcomeScreenImagePath,
  ] = watch([
    'surveyType',
    'triggerId',
    'emailNotificationEnabled',
    'dashboardNotificationEnabled',
    'dashboardBoxNotificationEnabled',
    'phoneNotificationEnabled',
    'nativeAppNotificationEnabled',
    'typeformConfig.welcomeScreenImagePath',
  ]);
  const trigger = triggers.find(({ id }) => id === triggerId);

  const onAddTrigger = (newTrigger: SerializedTrigger) => {
    insertTrigger(0, newTrigger);
    setShowNewTriggerForm(false);
    setShowTriggerSuccces(true);
  };
  const triggerOptions: [
    string,
    string
  ][] = triggers.map(({ id, internalName }) => [internalName, id]);
  const questionTypes: [
    string,
    string
  ][] = allTypeformQuestionTypes.map(([internalName, type]) => [
    internalName,
    type ? type.toString() : '',
  ]);

  const templateNamesOptions: [
    string,
    string
  ][] = templateNames.map(({ slug, name }) => [name, slug]);

  const workspaceHrefsOptions: [
    string,
    string
  ][] = typeformWorkspaces.map(({ href, name }) => [name, href]);

  const themesOptions: [
    string,
    string
  ][] = typeformThemes.map(({ id, name }) => [name, id]);

  const showTriggerForm = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowNewTriggerForm(true);
  };

  const loadSurveyClass = (newSurveyClass: SerializedSurveyClass) => {
    urlToData(
      newSurveyClass.typeformConfig.welcomeScreenImagePath,
      setWelcomeScreenImage
    );
    reset({
      ...newSurveyClass,
      id: undefined,
      internalName: `Copy-${newSurveyClass.internalName}`,
      typeformConfig: {
        ...newSurveyClass.typeformConfig,
        id: undefined,
      },
      reminders: newSurveyClass.reminders.map((reminder) => ({
        ...reminder,
        id: undefined,
        timingId: undefined,
        timing: {
          ...reminder.timing,
          id: undefined,
        },
      })),
    });
  };

  const uppy = useUppy(() => new Uppy());
  uppy.on('file-added', function (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file.data);
    reader.onloadend = function () {
      const base64data = reader.result;
      setWelcomeScreenImage(base64data);
    };
  });

  return (
    <>
      <LoadSurveyClass
        loadNewSurveyClass={loadSurveyClass}
        surveyClasses={surveyClasses}
      />

      {showNewTriggerForm ? (
        <TriggerForm
          triggerConfigurator={triggerConfigurator}
          createTriggerPath={triggerPath}
          onSuccess={onAddTrigger}
          method="POST"
        />
      ) : (
        <NewTriggerButtonWithMessage
          onClick={showTriggerForm}
          showMessage={showTriggerSuccess}
        />
      )}

      <Form description="Survey Class" {...adminForm}>
        <Input name="internalName" label="Internal Name" required />
        <Inputs description="Survey Settings">
          <Select
            label="Survey Type"
            name="surveyType"
            options={allTypes}
            includeBlank
            required
          />
          <Checkbox
            label="Require Q/A to be edited"
            name="requireFinalization"
            hint="(i.e, the questions and/or answers for each survey will be different, like a brunch menu, and so when you create event, will need to go into the survey and specify those)"
          />
        </Inputs>

        <Inputs description="Survey Content">
          <VariablesForSurveys />
          {surveyType === 'typeform' && (
            <Input name="typeformUrl" label="Typeform Url" required />
          )}
          {surveyType === 'typeform_hybrid' && (
            <Inputs description="Typeform Config">
              <Input label="Typeform Title" name="typeformConfig.title" />
              <Input
                label="Thank You Screen Title"
                name="typeformConfig.thankyouScreenTitle"
              />
              <TextArea
                label="Welcome Screen Description"
                name="typeformConfig.welcomeScreenDescription"
                options={{ rows: 20 }}
              />
              <Input
                label="Welcome Screen Title"
                name="typeformConfig.welcomeScreenTitle"
              />

              {typeof welcomeScreenImage === 'string' && (
                <img src={welcomeScreenImage} />
              )}
              {typeof welcomeScreenImage !== 'string' && (
                <img src={welcomeScreenImagePath} />
              )}
              <FileInput
                uppy={uppy}
                pretty={true}
                inputName="typeformConfig.welcomeScreenImageData"
              />

              <Select
                label="Workspace"
                name="typeformConfig.workspaceHref"
                options={workspaceHrefsOptions}
              />
              <Select
                label="Theme"
                name="typeformConfig.themeId"
                options={themesOptions}
              />
              <NestedFields
                description="Questions"
                resourceName="Question"
                name="typeformConfig.fields"
                defaultObject={emptyQuestion}
                requireRemoveConfirmation
              >
                {(questionIndex) => {
                  const namespace = `typeformConfig.fields.${questionIndex}`;
                  const questionType = watch(
                    `typeformConfig.fields.${questionIndex}.type` as const
                  );
                  return (
                    <>
                      <li>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="input">Priority</label>
                        {questionIndex + 1}
                      </li>
                      <Input label="Title" name={`${namespace}.title`} />
                      <Input
                        label="Description"
                        name={`${namespace}.description`}
                      />
                      <Checkbox
                        label="Required"
                        name={`${namespace}.required`}
                      />
                      <ReactSelect
                        label="Type"
                        name={`${namespace}.type`}
                        options={questionTypes}
                      />
                      {questionType === 'multiple_choice' && (
                        <>
                          <Checkbox
                            label="Allow Multiple Selection"
                            name={`${namespace}.allowMultipleSelection`}
                          />
                          <Checkbox
                            label="Allow Other Choices"
                            name={`${namespace}.allowOtherChoice`}
                          />
                        </>
                      )}
                      {(questionType === 'dropdown' ||
                        questionType === 'multiple_choice') && (
                        <NestedFields
                          name={`${namespace}.choices`}
                          resourceName="Choice"
                          defaultObject={{ text: '' }}
                        >
                          {(choiceIndex) => (
                            <Input
                              label="Answer Option"
                              name={`${namespace}.choices.${choiceIndex}.text`}
                            />
                          )}
                        </NestedFields>
                      )}
                    </>
                  );
                }}
              </NestedFields>
            </Inputs>
          )}
        </Inputs>

        <div id="js-announcement_trigger">
          <Inputs description="Survey Creation/Announcement Triggers">
            <li>
              This is when the survey can be entered and notifications may be
              sent via email, push notification, text, or site dashboard
              notification, topline or big box.
            </li>
            <LinkTo name="View All Triggers" href={triggersPath} />
            <ReactSelect
              name="triggerId"
              label="Trigger"
              options={triggerOptions}
              required
            />
            {trigger && (
              <Inputs description="Audiences Groups">
                {Object.entries(trigger.audiencesGroups).map(
                  ([className, audiences]) => (
                    <ReactSelect
                      label={className}
                      name={`audiencesGroups.${className}` as const}
                      options={audiences}
                      key={className}
                      multiple
                      required
                    />
                  )
                )}
              </Inputs>
            )}
          </Inputs>
        </div>

        <div id="js-deadline_trigger">
          <Inputs description="Deadline Triggers">
            <li>
              This is when the survey can no longer be entered and users no
              longer see the option to enter it on their dashboard.
            </li>
            <LinkTo name="View All Triggers" href={triggersPath} />
            <ReactSelect
              name="deadlineTriggerId"
              label="Trigger"
              options={triggerOptions}
              required
            />
          </Inputs>
        </div>

        <Inputs description="Survey Creation Notification Options:">
          <VariablesForSurveys />

          <Checkbox
            label="Dashboard Topline Alerts Reminder"
            name="dashboardNotificationEnabled"
          />
          {dashboardNotificationEnabled && (
            <>
              <img src={actionItemNotificationExamplePath} />
              <Input
                name="dashboardNotificationMessage"
                label="Notification Message"
              />
              <Input name="dashboardNotificationLinkText" label="Link Text" />
            </>
          )}

          <Checkbox
            label="Dashboard Large Box Alert"
            name="dashboardBoxNotificationEnabled"
          />
          {dashboardBoxNotificationEnabled && (
            <>
              <Input name="dashboardBoxNotificationTitle" label="Title" />
              <Input name="dashboardBoxNotificationBody" label="Body" />
              <Input
                name="dashboardBoxNotificationButtonText"
                label="Button Text"
              />
            </>
          )}

          <Checkbox
            label="Native App Push Message"
            name="nativeAppNotificationEnabled"
          />
          {nativeAppNotificationEnabled && (
            <Input
              name="nativeAppNotificationBody"
              label="Push Notification Message"
            />
          )}

          <Checkbox label="Phone Text" name="phoneNotificationEnabled" />
          {phoneNotificationEnabled && (
            <Input name="phoneNotificationBody" label="Text Message" />
          )}

          <Checkbox label="Email" name="emailNotificationEnabled" />
          {emailNotificationEnabled && (
            <ReactSelect
              label="Automated Notification Template"
              name="emailNotificationTemplate"
              options={templateNamesOptions}
            />
          )}
        </Inputs>

        <NestedFields
          description="Reminders"
          resourceName="Reminder"
          name="reminders"
          defaultObject={emptyReminder}
          requireRemoveConfirmation
        >
          {(reminderIndex) => {
            const namespace = `reminders.${reminderIndex}`;
            return (
              <SurveyReminderForm
                namespace={namespace}
                templateNamesOptions={templateNamesOptions}
                allReminderTypes={allReminderTypes}
              />
            );
          }}
        </NestedFields>

        <Inputs description="Response Results Email:">
          <li> Not implemented yet </li>
        </Inputs>

        <Actions>
          <Submit />
        </Actions>
      </Form>
    </>
  );
}

export default withQuery(SurveyClassForm);
