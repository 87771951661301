import React, { FormEvent, useId, useState } from 'react';
import Select from 'react-select';

import {
  NotificationClassOption,
  SerializedAutomatedNotification,
} from '../types';
import { Inputs } from './admin';

interface LoadNotificationClassProps {
  notificationClasses: NotificationClassOption[];
  addAutomatedNotifications: (a: SerializedAutomatedNotification[]) => void;
}

function LoadNotificationClass({
  notificationClasses,
  addAutomatedNotifications,
}: LoadNotificationClassProps) {
  const notificationClassesOptions = notificationClasses.map(
    ({ id: value, displayName: label }) => ({ value, label })
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedOption) return;

    const selectedNotificationClass = notificationClasses.find(
      (notificationClass) => {
        return notificationClass.id === selectedOption.value;
      }
    );

    if (!selectedNotificationClass) return;

    const response = await fetch(
      selectedNotificationClass.getNotificationClassPath,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }
    );

    if (response.ok) {
      const {
        resource: { automatedNotifications },
      } = (await response.json()) as {
        resource: { automatedNotifications: SerializedAutomatedNotification[] };
      };
      addAutomatedNotifications(automatedNotifications);
      setSelectedOption(null);
      setIsLoaded(true);
    }
  };

  const id = useId();

  return (
    <form onSubmit={handleSubmit}>
      <Inputs description="Load existing Notification Class" atRoot>
        <li>
          <label htmlFor={id} className="string input required stringish">
            <p>Select existing Notification class</p>
          </label>
          <Select
            id={id}
            value={selectedOption}
            onChange={setSelectedOption}
            options={notificationClassesOptions}
            menuPosition="fixed"
          />
        </li>
        <button className="btn -primary -lg" disabled={!selectedOption}>
          Copy
        </button>
        <li>
          {isLoaded && (
            <p>
              <strong>Notification class loaded successfully!</strong>
            </p>
          )}
        </li>
      </Inputs>
    </form>
  );
}

export default LoadNotificationClass;
