import React, { ReactNode } from 'react';

interface ColumnsProps {
  children: ReactNode;
}

function Columns({ children }: ColumnsProps) {
  return (
    <div className="columns">
      {children}
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}

export default Columns;
