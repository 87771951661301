import cn from 'classnames';
import React from 'react';
import { get, useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { useList } from 'react-use';

import { OptionType } from './types';

function optionObject(option: OptionType) {
  if (Array.isArray(option)) {
    return { label: option[0], value: option[1] };
  }
  return { label: option, value: option };
}

interface ReactSelectProps {
  label?: string;
  name: string;
  options: OptionType[];
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  withCustomOption?: boolean;
}

function ReactSelect(props: ReactSelectProps) {
  const {
    label,
    name,
    options: initialOptions,
    required,
    multiple,
    disabled,
    withCustomOption,
  } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { field } = useController({ name, control });
  const [customOptions, { push }] = useList<string>([]);
  const onCreateOption = (newOption: string) => {
    push(newOption);
    if (multiple) {
      field.onChange([...field.value, newOption]);
    } else {
      field.onChange(newOption);
    }
  };
  const options = initialOptions.concat(customOptions);
  const optionsConfig = options.map(optionObject);

  return (
    <li className={cn('select', 'input', { required })}>
      {label && (
        <label className={cn('select', 'input', { required })}>
          <p>{label}</p>
        </label>
      )}
      {withCustomOption ? (
        multiple ? (
          <Creatable
            onChange={(newOptions) => {
              if (newOptions === null) {
                field.onChange([]);
              } else {
                field.onChange(newOptions.map(({ value }) => value));
              }
            }}
            onCreateOption={onCreateOption}
            value={optionsConfig.filter(({ value }) =>
              field.value?.map(String).includes(String(value))
            )}
            options={optionsConfig}
            classNamePrefix={label && label.split(' ').join('')}
            isDisabled={disabled}
            menuPosition="fixed"
            formatCreateLabel={(inputText) =>
              `"${inputText}" Press Enter to create.`
            }
            isMulti
          />
        ) : (
          <Creatable
            {...field}
            onChange={(option) => field.onChange(option?.value)}
            value={optionsConfig.find(
              ({ value }) => String(value) === String(field.value)
            )}
            onCreateOption={onCreateOption}
            options={optionsConfig}
            isDisabled={disabled}
            menuPosition="fixed"
            formatCreateLabel={(inputText) =>
              `"${inputText}" Press Enter to create.`
            }
            classNamePrefix={label && label.split(' ').join('')}
          />
        )
      ) : multiple ? (
        <Select
          {...field}
          onChange={(newOptions) => {
            if (newOptions === null) {
              field.onChange([]);
            } else {
              field.onChange(newOptions.map(({ value }) => value));
            }
          }}
          value={optionsConfig.filter(({ value }) =>
            field.value?.map(String).includes(value)
          )}
          menuPosition="fixed"
          options={optionsConfig}
          classNamePrefix={label && label.split(' ').join('')}
          isDisabled={disabled}
          isMulti
        />
      ) : (
        <Select
          {...field}
          onChange={(option) => field.onChange(option?.value)}
          value={optionsConfig.find(
            ({ value }) => String(value) === String(field.value)
          )}
          options={optionsConfig}
          menuPosition="fixed"
          isDisabled={disabled}
          classNamePrefix={label && label.split(' ').join('')}
        />
      )}
      <p className="inline-errors">{get(errors, name)?.message}</p>
    </li>
  );
}

export default ReactSelect;
