import React, { ReactNode } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import Inputs from './Inputs';

interface NestedFieldsProps<T> {
  description?: string;
  children: (index: number) => ReactNode;
  name: string;
  resourceName: string;
  defaultObject: T;
  requireRemoveConfirmation?: boolean;
  disabled?: boolean;
}

function NestedFields<T>({
  description,
  children,
  name,
  resourceName,
  defaultObject,
  requireRemoveConfirmation,
  disabled,
}: NestedFieldsProps<T>) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const removeField = (index: number) => {
    if (requireRemoveConfirmation) {
      window.confirm(`Are you sure you want to remove this ${resourceName}?`) &&
        remove(index);
    } else {
      remove(index);
    }
  };

  return (
    <Inputs description={description} disabled={disabled} atRoot>
      {fields.map((field, index) => (
        <Inputs key={field.id} description={resourceName}>
          {children(index)}
          <button
            type="button"
            onClick={() => removeField(index)}
            disabled={disabled}
          >
            {`Remove ${resourceName}`}
          </button>
        </Inputs>
      ))}
      <button
        type="button"
        onClick={() => append(defaultObject)}
        disabled={disabled}
      >
        {`Add ${resourceName}`}
      </button>
    </Inputs>
  );
}

export default NestedFields;
