import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FC } from 'react';

const queryClient = new QueryClient();

function withQuery<T extends object>(Component: FC<T>) {
  return (props: T) => (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  );
}

export default withQuery;
