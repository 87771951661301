import React, { FormEvent, useId, useState } from 'react';
import Select from 'react-select';

import { SerializedSurveyClass } from '../types';

interface LoadSurveyClassProps {
  surveyClasses: SerializedSurveyClass[];
  loadNewSurveyClass: (argument: SerializedSurveyClass) => void;
}

function LoadSurveyClass({
  surveyClasses,
  loadNewSurveyClass,
}: LoadSurveyClassProps) {
  const surveyClassesOptions = surveyClasses.map(
    ({ id: value, internalName: label }) => ({ value, label })
  );
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedOption) return;

    const selectedSurveyClass = surveyClasses.find((surveyClass) => {
      return surveyClass.id === selectedOption.value;
    });

    if (!selectedSurveyClass) return;

    const response = await fetch(selectedSurveyClass.getSurveyClassPath, {
      headers: { 'Content-Type': 'application/json' },
      method: 'GET',
    });

    if (response.ok) {
      const { resource } = (await response.json()) as {
        resource: SerializedSurveyClass;
      };
      loadNewSurveyClass(resource);
    }
  };

  const id = useId();

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="inputs">
        <legend>
          <span />
        </legend>
        <ol>
          <li>
            <label htmlFor={id} className="string input required stringish">
              <p>Select existing survey class</p>
            </label>
            <Select
              id={id}
              value={selectedOption}
              onChange={setSelectedOption}
              options={surveyClassesOptions}
              menuPosition="fixed"
            />
          </li>
          <button className="btn -primary -lg" disabled={!selectedOption}>
            Load Existing Survey Class
          </button>
        </ol>
      </fieldset>
    </form>
  );
}

export default LoadSurveyClass;
