import React, { ReactNode } from 'react';

interface ActionProps {
  children: ReactNode;
}

function Actions(props: ActionProps) {
  const { children } = props;
  return (
    <fieldset className="actions">
      <ol>{children}</ol>
    </fieldset>
  );
}

export default Actions;
