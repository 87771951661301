import { SurveyReminder, Timing, TypeformQuestion } from './types';

export const emptyQuestion: Readonly<TypeformQuestion> = {
  description: '',
  title: '',
  type: null,
  required: false,
  allowOtherChoice: false,
  allowMultipleSelection: false,
  choices: [],
};

export const flatpickrDatetimeConfig = {
  enableTime: true,
  altInput: true,
  allowInput: true,
  altFormat: 'm/d/Y, h:i K',
  dateFormat: 'Y-m-dTh:i K',
  time_24hr: false,
};

export const flatpickrDateConfig = {
  enableTime: false,
  altInput: true,
  allowInput: true,
  altFormat: 'm/d/Y',
  dateFormat: 'Y-m-d',
};

export const emptyTiming: Readonly<Timing> = {
  id: null,
  type: 'after',
  atTime: false,
  weeks: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  atHour: 0,
  atAmPm: 'am',
  atMinute: 0,
};

export const emptyReminder: Readonly<SurveyReminder> = {
  id: null,
  reminderType: 'after_announcement',
  timing: emptyTiming,
  sendSms: false,
  sendPushNotification: false,
  sendEmail: false,
  emailSlug: '',
  pushNotificationBody: '',
  pushNotificationTitle: '',
  smsBody: '',
};
