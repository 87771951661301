import React, { useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import { useTriggers, useVariablesClasses } from '../queries';
import enure from '../utils/ensure';
import {
  Column,
  Columns,
  Panel,
  Suspense,
  TableFor,
  withQuery,
  withSuspense,
} from './admin';

interface TriggerSelectProps {
  onChange: (triggerId: number | null) => void;
}

function TriggerSelect({ onChange }: TriggerSelectProps) {
  const triggers = useTriggers();

  const onChangeInternal = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    onChange(value ? Number(value) : null);
  };

  return (
    <select onChange={onChangeInternal}>
      <option value="">All triggers</option>
      {triggers.collection.map((trigger) => (
        <option key={trigger.id} value={trigger.id}>
          {trigger.internalName}
        </option>
      ))}
    </select>
  );
}

function Variable({ text }: { text: string }) {
  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  function onClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    copy(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  }

  return (
    <>
      {text}{' '}
      <a href="#" onClick={onClick}>
        {isCopied ? 'copied' : 'copy'}
      </a>
    </>
  );
}

function Variables() {
  const [triggerId, setTriggerId] = useState<number | null>(null);
  const variablesClasses = useVariablesClasses(triggerId);

  return (
    <Columns>
      <Column>
        <Panel description="Filter by trigger">
          <Suspense>
            <TriggerSelect onChange={setTriggerId} />
          </Suspense>
        </Panel>

        {enure(variablesClasses).map((variablesClass) => (
          <Panel
            description={variablesClass.description}
            key={variablesClass.description}
          >
            <TableFor
              collection={variablesClass.variablesWithDescriptions}
              columns={{
                variable: [
                  (element) => <Variable text={element[0]} />,
                  { width: '30%' },
                ],
                description: (element) => element[1],
              }}
            />
          </Panel>
        ))}
      </Column>
    </Columns>
  );
}

export default withQuery(withSuspense(Variables));
