import React from 'react';

interface LinkToProps {
  name: string;
  href: string;
}

function LinkTo(props: LinkToProps) {
  const { name, href } = props;
  return (
    <ol>
      <li>
        <a href={href} target="_blank" rel="noreferrer">
          {name}
        </a>
      </li>
    </ol>
  );
}

export default LinkTo;
