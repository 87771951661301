import React, { ReactNode } from 'react';

type ColumnMapper<T> = (element: T) => ReactNode;
type ColumnConfigOptions = { width?: string };
type ColumnConfigArray<T> = [ColumnMapper<T>, ColumnConfigOptions?];
type ColumnConfig<T> = ColumnMapper<T> | ColumnConfigArray<T>;

function isColumnConfigArray<T>(
  column: ColumnConfig<T>
): column is ColumnConfigArray<T> {
  return Array.isArray(column);
}

interface PanelProps<T> {
  collection: T[];
  columns: { [name: string]: ColumnConfig<T> };
}

function columnStyle<T>(columnConfig: ColumnConfig<T>) {
  if (isColumnConfigArray(columnConfig)) {
    const options = columnConfig[1];
    if (options && options.width) {
      return { width: options.width };
    }
  }
  return {};
}

function TableFor<T>({ collection, columns }: PanelProps<T>) {
  return (
    <table border={0}>
      <thead>
        <tr>
          {Object.entries(columns).map(([columnName, columnConfig]) => (
            <th
              key={columnName}
              style={columnStyle(columnConfig)}
              className="col"
            >
              {columnName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {collection.map((element, index) => (
          <tr key={index} className={index % 2 === 0 ? 'odd' : 'even'}>
            {Object.values(columns).map((column, index) => (
              <td key={index} className="col">
                {isColumnConfigArray(column)
                  ? column[0](element)
                  : column(element)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableFor;
