import parse from 'html-react-parser';
import React, { useState } from 'react';

export interface InterestedExternalEventProps {
  externalEventId: number;
  userInterested: boolean;
  interestText: string;
  interestedText: string;
}

function InterestExternalEvent({
  externalEventId,
  userInterested,
  interestText,
  interestedText,
}: InterestedExternalEventProps) {
  const [interest, setInterest] = useState(userInterested);

  const interestByUser = async () => {
    fetch(`/api/v1/external_events/${externalEventId}/interested`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
  };

  const uninterestByUser = async () => {
    fetch(`/api/v1/external_events/${externalEventId}/uninterested`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    });
  };

  const onClickButton = () => {
    if (interest) {
      uninterestByUser();
      setInterest(false);
    } else {
      interestByUser();
      setInterest(true);
    }
  };
  return (
    <>
      <p className="checkbox-button-wrapper__description">
        {interest ? parse(interestedText) : parse(interestText)}
      </p>
      <button
        type="button"
        className={`checkbox-button btn ${
          interest ? '-secondary' : '-primary'
        }`}
        onClick={onClickButton}
      >
        <input
          id="checkbox_interest"
          type="checkbox"
          className="checkbox-button__input"
          checked={interest}
        />
        <label className="checkbox-button__label" htmlFor="checkbox_interest">
          &nbsp;Interested
        </label>
      </button>
    </>
  );
}

export default InterestExternalEvent;
